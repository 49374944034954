
import { defineComponent } from '@vue/runtime-core';
import chatModule from '@/Chat/module';
import { goToQuestionnaire } from '@/Study/helpers'

export default defineComponent({
    mounted(){
        if(!this.$store.hasModule('chat'))
            this.$store.registerModule('chat', chatModule);
    },
    methods: { goToQuestionnaire }
});
